@font-face {
  font-family: 'FuturaPT';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url('FuturaPTLight.otf') format('opentype');
}

@font-face {
  font-family: 'FuturaPT';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('FuturaPTMedium.otf') format('opentype');
}